<template>
  <div>
    <van-dropdown-menu class="dropdown">
      <van-dropdown-item
        v-model="classify"
        :options="classifys"
        @click="confirmClassify"
      ></van-dropdown-item>
      <!-- <van-dropdown-item v-model="type" :options="types" />
      <van-dropdown-item v-model="sort" :options="sorts" /> -->
      <van-dropdown-item :title="condition.regionTitle" ref="region">
        <van-area
          :value="region"
          :area-list="regions"
          columns-num="2"
          item-height="30"
          @cancel="cancelRegion"
          @confirm="confirmRegion"
        />
      </van-dropdown-item>
      <van-dropdown-item v-model="condition.sort" :options="sorts">
      </van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>
<script>
import { DropdownMenu, DropdownItem, Collapse, CollapseItem, CheckboxGroup, Checkbox, Button, Popup, Area, TreeSelect } from 'vant'
export default {
  components: {
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [TreeSelect.name]: TreeSelect
  },
  data () {
    return {
      classify: '',
      classifys: [
        { text: '全部工种', value: '' },
        { text: '木工', value: '木工' },
        { text: '瓦工', value: '瓦工' },
        { text: '水电', value: '水电' },
        { text: '油漆', value: '油漆' },
        { text: '流水线', value: '流水线' },
        { text: '装卸工', value: '装卸工' }
      ],
      sorts: [
        { text: '选择排序', value: '' },
        { text: '薪资排序', value: 'SAR' },
        { text: '时间排序', value: 'TIM' }
      ],

      type: 0,
      activeNames: ['1'],
      conditions: [
        { items: [], name: '单位行业', key: 'IDT', checked: [] },
        { items: [], name: '单位性质', key: 'IQA', checked: [] },
        { items: [], name: '单位类型', key: 'ITP', checked: [] },
        { items: [], name: '单位规模', key: 'ISA', checked: [] },
        { items: [], name: '岗位类型', key: 'STP', checked: [] }
      ],
      condition: { regionTitle: '城市', sort: '', job: [] },
      regionPopupShow: false,
      regions: {},
      region: '610100',
      jobs: [],
      activeJobIndex: 0
    }
  },
  props: {
    callback: Function
  },
  mounted () {
    this.retrieveRegions()
  },
  methods: {
    confirmRegion (val) {
      var code = val[1].code
      this.condition.region = code
      this.region = code
      this.condition.regionTitle = val[1].name
      this.cacheCondition()
      this.callback(this.condition)
      this.$refs.region.toggle()
    },
    cancelRegion () {
      this.condition.region = ''
      this.condition.regionTitle = '城市'
      this.cacheCondition()
      this.callback(this.condition)
      this.$refs.region.toggle()
    },

    confirmClassify () {
      this.condition.classify = this.classify
      this.cacheCondition()
      this.callback(this.condition)
    },
    reset () {
      this.conditions.forEach(function (item) {
        item.checked = []
      })
    },
    async retrieveRegions () {
      var pd = { level: 2 }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.regions.province_list = res.data.one
        this.regions.city_list = res.data.two
      }
    },
    initCondition (condition) {
      this.classify = condition.classify
    },
    cacheCondition () {
      window.sessionStorage.setItem(window.const.global.RECRUIT_SEARCH_SIFT, JSON.stringify(this.condition))
    },
    initConditionCache () {
      var condition = window.sessionStorage.getItem(window.const.global.RECRUIT_SEARCH_SIFT)
      if (condition !== undefined && condition != null && condition !== '') {
        var condObj = JSON.parse(condition)
        this.condition = condObj
        this.callback(this.condition)
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .dropdown {
  .van-dropdown-menu__bar {
    position: relative;
    display: flex;
    height: 35px;
    background-color: #fff;
    border-bottom: 1px solid #ebedf0;
    box-shadow: 0 0px 0px rgba(100, 101, 102, 0.12);
  }
  .van-dropdown-menu__title {
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 8px;
    color: #323233;
    font-size: 14px;
    line-height: 22px;
  }
  .van-collapse-item__content {
    padding: 8px 16px;
    color: #969799;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding: 5px 0px 5px 20px;
    border-bottom: 1px solid #eee;
  }

  .collapse {
    text-align: left;
  }
  .button {
    margin: 10px;
  }
}
</style>
